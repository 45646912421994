var itemMixin = {
    methods: {
        filterId(slugged = '') {
            return slugged.split('-').pop()
        },
        allergens(arr = []) {        
            if(this.menus?.included?.allergens instanceof Array) {
                return this.menus.included.allergens.filter(allergen => {
                    return arr.includes(allergen.id)
                })
            }
            return []            
        } 
    }
}

export default itemMixin