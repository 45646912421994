<template>
    <v-footer class="footer d-flex justify-center py-5" color="primary" fixed>
        <v-container class="mx-1 px-1">
            <section class="d-flex align-center">
                <v-btn color="white" fab class="primary--text"  depressed width="57" height="57"><v-icon size="32" color="primary">mdi-receipt</v-icon></v-btn>
                <p class="footer-description mx-5" v-html="total_vat_included(translate('total_vat_included','_TOTAL_ TOTAL <span>(VAT included)</span>'),`${total} ${translate('sar_total', 'SAR')}`)"></p>
            </section>
            <section class="d-flex justify-space-between mt-8">
                <solo-button @click="$router.push({name:'menu.index'})"  width="346" inverse-outlined>{{translate('go_back','GO BACK')}}</solo-button>
                <solo-button @click="$emit('next-step')" inverse width="606">
                    <span class="mx-2">{{translate('place_order','PlACE ORDER')}}</span>
                </solo-button>
            </section>
        </v-container>
    </v-footer>
</template>
<script>
import { mapGetters } from 'vuex';
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import SoloButton from '@/components/base/SoloButton.vue';
export default {
    name:'BaseFooter',
    mixins: [itemMixin, translationMixin],
    components:{
        SoloButton
    },
    computed:{
        ...mapGetters({
            items: 'cart/items'
        }),
        total() {
            return this.items.map(({total, quantity}) => {
                return total * quantity
            }).reduce((a,b) => { return a + b }, 0)
        }        
    },
}
</script>
<style lang="scss" scoped>
.footer{
    height: 274px;
    box-shadow: 0px -23px 46px rgba(0, 0, 0, 0.03) !important;
    .container{
        max-width: 1000px !important;
        height: 100% !important;
    }
 
    &-description{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 100%;
        color: #FFFFFF;
        span{
            font-weight: 300;
            font-size: 36px;
        }
    }
}
</style> 